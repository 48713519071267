import AlertHandler from '../../../components/alert/alert';
import axios from 'axios';

const alertHandler = new AlertHandler();
const ERRORS = {
    zoneFeaturesError: 'При получении списка зон доставки произошла ошибка',
    yMapsLoaderError: 'Произошла ошибка при загрузке библиотеки API Яндекс Карт',
    zoneIdError: 'Не удалось определить зону доставки. Повторите попытку позже'
};
const MESSAGE = {
    zoneIdMess: 'Нам жаль, но по выбранному вами адресу мы пока что не доставляем заказы. Но мы постоянно улучшаем наш сервис, в скором времени доставка будет и там'
};
const MAP_SECTION_ID = 'map-zones';
const REGIONS_DATA = {
  '9753': 'Московская область',
  '9754': 'Ленинградская область',
};

const fetchSuggestData = async(address) => {
    let data = {isSucceed: false, data: null};

    try {
        const {data: suggestData} = await axios.post('/ajax/dadata/getAddress.php', {action: 'suggest', address});

        data = {isSucceed: true, data: suggestData};
    } catch (error) {
        console.error(error);
    }

    return data;
};

const fetchSuggestValue = async(address) => {
    let data = {isSucceed: false, data: null};

    try {
        const {data: addressData} = await axios.post('/ajax/dadata/getAddress.php', {action: 'clean', address});

        data = {isSucceed: true, data: addressData};
    } catch (error) {
        console.error(error);
    }

    return data;
};

const getZones = async() => {
    let data = {isSucceed: false, data: null};

    try {
        const response = await axios.get('/ajax/zone/zones.json.dat');

        data = {
            isSucceed: true,
            data: response.data
        };
    } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.zoneFeaturesError);
    }

    console.log('getZones: ', data);

    return data;
};

const loadYMaps = async(address) => {
    let newc;
    let data = {isSucceed: false, data: null};

    console.log({address});

    try {
        const [zonesResp, suggestResp, ymapsResp] = await Promise.all([
            getZones(),
            fetchSuggestValue(address),
            new Promise((resolve) => ymaps.ready(resolve))
        ]);
        const {data: zonesData} = zonesResp;
        const {data: suggestData} = suggestResp;

        if (zonesResp.isSucceed && zonesData && suggestResp.isSucceed && suggestData) {
            zonesData.features.forEach(function (f) {
                f.geometry.coordinates[0].forEach(function (c, i) {
                    newc = c[1];
                    c[1] = c[0];
                    c[0] = newc;
                });
                f.options = f.properties;
            });

            const { lat, lon } = suggestData;
            const map = new ymapsResp.Map(MAP_SECTION_ID, {center: [55.43, 37.75], zoom: 8});
            const deliveryZones = ymapsResp.geoQuery(zonesData).addToMap(map);
            //const pointResult = ymapsResp.geoQuery(ymapsResp.geocode(address), {kind: 'house', results: 1});
            const pointResult = ymapsResp.geoQuery([lat, lon].map(Number), {kind: 'house', results: 1});

            data = {
                isSucceed: true,
                data: {
                    pointResult,
                    deliveryZones,
                    coords: [lat, lon].map(Number)
                }
            };
        } else {
            alertHandler.showAlert(ERRORS.yMapsLoaderError);
        }
    } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.yMapsLoaderError);
    }

    console.log('loadYMaps: ', data);

    return data;
};

const getZoneId = async(address) => {
    let data = {isSucceed: false, zoneId: null};
    const mapSection = document.querySelector(`#${MAP_SECTION_ID}`);

    if (!mapSection) {
        console.log('mapSection: ', mapSection);

        return;
    }

    try {
        const {isSucceed, data: zoneData} = await loadYMaps(address);

        if (isSucceed && zoneData) {
            const polygon = zoneData.deliveryZones.searchContaining(zoneData.coords).get(0);

            data = {
                isSucceed: true,
                zoneId: polygon.properties.get('description').split(';')[1] || null,
                zoneName: polygon.properties.get('description').split(';')[0] || null,
                lat: zoneData.coords[0],
                lon: zoneData.coords[1]
            };
            /*
            const response = await zoneData.pointResult;

            if (zoneData.pointResult.get(0)) {
                zoneData.pointResult.get(0);

                const coords = zoneData.pointResult.get(0).geometry.getCoordinates();
                const polygon = zoneData.deliveryZones.searchContaining(coords).get(0);

                data = {
                    isSucceed: true,
                    zoneId: polygon.properties.get('description').split(';')[1] || null,
                    zoneName: polygon.properties.get('description').split(';')[0] || null,
                    lat: coords[0],
                    lon: coords[1]
                };
            } else {
                data = {isSucceed: true, zoneId: null};
            }

            if (!data.zoneId) {
                alertHandler.showAlert(MESSAGE.zoneIdMess);
            }
            */
        } else {
            data = {isSucceed: true, zoneId: null};
            alertHandler.showAlert(ERRORS.zoneIdError);
        }
    } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.zoneIdError);
    }

    console.log('getZoneId: ', data);

    return data;
};

const getRegionId = (address) => {
    const regionsData = {
        '9497': 'Москва',
        '9498': 'Санкт-Петербург',
        ...REGIONS_DATA
    };

    const index = Object.values(regionsData).findIndex((item) => address.includes(item));

    return Number(Object.keys(regionsData)[index]);
};

export {
    REGIONS_DATA,
    getRegionId,
    getZoneId,
    fetchSuggestData,
    fetchSuggestValue
};
